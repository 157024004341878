<template>
  <div>
    <div class="d-flex justify-content-between mb-1">
      <h3>เช็ค Prompt Pay ({{ data_count }})</h3>
      <b-button variant="success" @click="random_data()" size="sm"
        >Random</b-button
      >
    </div>
    <b-card v-for="(x, index) of screen_data" :key="index">
      <div class="row">
        <div class="col-md-5 col-6">
          <h3>ข้อมูล</h3>
          <hr />
          <div class="d-flex justify-content-between mb-1">
            <span>ชื่อ </span>
            <h3>{{ x.first_name }} {{ x.last_name }}</h3>
          </div>
          <div class="d-flex justify-content-between mb-1">
            <span>ชื่อ EN</span>
            <h3>{{ x.en_first_name }} {{ x.en_last_name }}</h3>
          </div>

          <div class="d-flex justify-content-between mb-1">
            <span>บัตรประชาชน </span>
            <h3>{{ x.card_id | card_id }}</h3>
          </div>

          <div class="d-flex justify-content-between mb-1">
            <span>เบอร์โทร </span>
            <h3>{{ x.phone | phone }}</h3>
          </div>
        </div>
        <div class="col-md-7 col-6">
          <h3>Screening</h3>
          <hr />
          <div class="mb-2 ml-2">
            <span>มี Prompt Pay ?</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                class="mt-1"
                v-model="form.pp_detect[x._id]"
                :name="some_radios1[x._id]"
                :value="true"
              >
                มี
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_detect[x._id]"
                :name="some_radios1[x._id]"
                :value="false"
              >
                ไม่มี
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_detect[x._id]"
                :name="some_radios1[x._id]"
                :value="null"
              >
                ยังไม่ได้เช็ค
              </b-form-radio>
            </div>
          </div>
          <div class="mb-2 ml-2" v-show="form.pp_detect[x._id] === true">
            <span>ชื่อตรง ?</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                class="mt-1"
                v-model="form.pp_same_name[x._id]"
                :name="some_radios2[x._id]"
                :value="true"
              >
                ตรง
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_same_name[x._id]"
                :name="some_radios2[x._id]"
                :value="false"
              >
                ไม่ตรง
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_same_name[x._id]"
                :name="some_radios2[x._id]"
                :value="null"
              >
                ยังไม่ได้เช็ค
              </b-form-radio>
            </div>
          </div>

          <div class="mb-2 ml-2" v-if="form.pp_same_name[x._id] === false">
            <span>ชื่อใน PromptPay</span>
            <div class="demo-inline-spacing">
              <b-form-input class="mt-1" v-model="form.pp_name_found[x._id]">
                ตรง
              </b-form-input>
            </div>
          </div>

          <div class="mb-2 ml-2" v-show="form.pp_detect[x._id] === true">
            <span>เลขบัตรประชาชน?</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                class="mt-1"
                v-model="form.pp_cardid[x._id]"
                :name="some_radios3[x._id]"
                :value="true"
              >
                มี
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_cardid[x._id]"
                :name="some_radios3[x._id]"
                :value="false"
              >
                ไม่มี
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_cardid[x._id]"
                :name="some_radios3[x._id]"
                :value="null"
              >
                ยังไม่ได้เช็ค
              </b-form-radio>
            </div>
          </div>
          <div class="mb-2 ml-2" v-show="form.pp_detect[x._id] === true">
            <span>เบอร์โทร?</span>
            <div class="demo-inline-spacing">
              <b-form-radio
                class="mt-1"
                v-model="form.pp_phone[x._id]"
                :name="some_radios4[x._id]"
                :value="true"
              >
                มี
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_phone[x._id]"
                :name="some_radios4[x._id]"
                :value="false"
              >
                ไม่มี
              </b-form-radio>
              <b-form-radio
                class="mt-1"
                v-model="form.pp_phone[x._id]"
                :name="some_radios4[x._id]"
                :value="null"
              >
                ยังไม่ได้เช็ค
              </b-form-radio>
            </div>
          </div>

          <div class="text-right">
            <b-button variant="success" @click="save_screening(x._id)"
              >Save</b-button
            >
          </div>
        </div>
      </div>
    </b-card>
  </div>
</template>

<script>
import { BCard, BFormRadio, BButton, BFormInput } from "bootstrap-vue";

export default {
  components: {
    BCard,
    BFormRadio,
    BButton,
    BFormInput,
  },

  data() {
    return {
      screen_data: [],
      loading: false,
      form: {
        pp_detect: {},
        pp_cardid: {},
        pp_phone: {},
        pp_same_name: {},
        pp_name_found: {},
      },
      some_radios1: {},
      some_radios2: {},
      some_radios3: {},
      some_radios4: {},
      data_count: 0,
    };
  },
  created() {
    this.LoadData();
  },
  methods: {
    LoadData(limit = 1, random = false) {
      this.loading = true;
      const form = {
        limit: limit,
        random: random,
      };

      form.per_page = 1;
      this.$http({
        method: "POST",
        url: `/member/promptpay/check`,
        data: form,
      }).then((x) => {
        this.loading = false;
        this.screen_data = x.data.data.screen_data;
        this.data_count = x.data.data.data_count;
      });
    },
    save_screening(id) {
      // Transform the data
      const newForm = Object.keys(this.form).reduce((result, key) => {
        if (this.form[key][id] !== undefined) {
          result[key] = this.form[key][id];
        }
        return result;
      }, {});

      this.$http({
        method: "PUT",
        url: `/member/promptpay/save/${id}`,
        data: newForm,
      }).then((x) => {
        this.loading = false;
        this.LoadData();
        // this.screen_data = x.data.data;
      });
    },
    random_data() {
      this.LoadData(1, true);
    },
  },
  filters: {
    phone(value) {
      if (!value) return "";
      const phone = value.toString();
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, "$1 $2 $3");
    },
    card_id(value) {
      if (!value) return "";
      const phone = value.toString();
      return phone.replace(
        /(\d{1})(\d{4})(\d{5})(\d{2})(\d{1})/,
        "$1 $2 $3 $4 $5"
      );
    },
  },
};
</script>

<style></style>
